<template>
  <div class="boxAll">
    <!-- 图标 标题 -->
    <div class="topTitle">
      <img
        src="@/assets/images/logo.png"
        alt="安泰乐"
        class="topLogo"
      > 网上生命纪念馆
    </div>
    <!-- 纵向轮播 -->
    <!-- <van-swipe
      style="height: 100%;"
      vertical
    > -->
    <!-- 建馆方式 -->
    <!-- <van-swipe-item
        style="background:#000000;"
        class="vantItem"
      > -->
    <div class="itemContainer">
      <div class="swiperItemTitlePc">
        <img src="@/assets/images/titleleft@2x.png" class="arrowImg" alt="">
        <span>电脑建馆</span>
        <img src="@/assets/images/titleright@2x.png" class="arrowImg" alt="">
      </div>
      <div>
        <div class="swiperItemTitlePcImg">
          <img src="@/assets/images/Computer@2x.png" alt="">
        </div>
        <div class="swiperItemTitlePcTextAll">
          <div class="swiperItemTitlePcTextItem">
            <span>使用浏览器打开</span>
            <br>
            <span>https://www.a tl-life.com/</span>
          </div>
          <div class="swiperItemTitlePcTextItem" style="width:2.16rem;">注册登录<br>会员中心</div>
          <div class="swiperItemTitlePcTextItem">新建纪念馆</div>
        </div>
      </div>
      <div class="swiperItemTitleMob">
        <img src="@/assets/images/titleleft@2x.png" class="arrowImg" alt="">
        <span>手机建馆</span>
        <img src="@/assets/images/titleright@2x.png" class="arrowImg" alt="">
      </div>
      <div>
        <div class="swiperItemTitlePcImg">
          <img src="@/assets/images/phone@2x.png" alt="">
        </div>
        <div class="swiperItemTitlePcTextAll">
          <div class="swiperItemTitlePcTextItem">
            <span>下载APP</span>
          </div>
          <div class="swiperItemTitlePcTextItem" style="width:2.16rem;">注册登陆</div>
          <div class="swiperItemTitlePcTextItem" >新建纪念馆</div>
        </div>
      </div>

      <!-- 建馆方式按钮 下载APP -->
      <!-- <div class="downLoadAppBtn" @click="goDownLoad">
        下载APP
      </div> -->
        <div class="downLoads">
            <div class="show-click">
               <!-- @click.prevent="goApple" -->
               
          <a   href="https://apps.apple.com/cn/app/id1473078077" >
            <img class="app-icon" src="@/assets/images/ios.png" alt="" />
            <p>APP Store</p>
          </a>
          <a href="https://www.yunzhongci.com/download/ATL.apk">
            <img class="app-icon" src="@/assets/images/Android.png" alt="" />
            <p>Android</p>
          </a>
        </div>
        </div>
    </div>
    <!-- 建馆方式文字描述 -->
    <div class="modelRecommendTextAll">
      <div class="modelRecommendText">
        安泰乐APP是一款为用户提供综合性 的线上服务，包括生命纪念馆（时光 纪），自传书， 云家谱。以及捐建福 位的用户所使用的业务关系管理等 。
      </div>
    </div>
    <!-- </van-swipe-item> -->
    <!-- 第二张 -->
    <!-- <van-swipe-item
        style="background:#000000"
        class="vantItem"
      > -->
    <div class="containerTwoAll">
      <div class="itemContainerTWOTitle">
        APP更多功能等你发现
      </div>
      <div class="itemContainerTwo">
        <div class="itemContainerTwoDiv">
          <img src="@/assets/images/Spectrum.png" alt="">
        </div>
        <!-- <div class="itemContainerTwo">
         
          <div class="itemContainerTwoLeft">
            <div class="itemContainerTwoLeftItem">
              <div class="itemContainerTwoItemTitle">
                传记书
              </div>
              <div class="itemContainerDesc">
                浓缩人生故事，汇成 一本书，那是无价的 也是传承
              </div>
              <div class="itemContainerImgDot">

              </div>
              <div class="itemContainerImg">

              </div>
            </div>
            <div class="itemContainerTwoLeftItem">
              <div class="itemContainerTwoItemTitle">
                福位管理
              </div>
              <div class="itemContainerDesc">
                管理凭证信息、福位 启用等功能
              </div>
              <div class="itemContainerImgDot">

              </div>
              <div class="itemContainerImg">

              </div>
            </div>
          </div>
          <div class="line">

          </div>
         
          <div class="itemContainerTwoRight">
            <div class="itemContainerTwoLeftItem">
              <div class="itemContainerTwoItemTitle">
                时光纪
              </div>
              <div class="itemContainerImgDot">

              </div>
              <div class="itemContainerImg">

              </div>
            </div>
            <div class="itemContainerTwoLeftItem">
              <div class="itemContainerTwoItemTitle">
                云家谱
              </div>
              <div class="itemContainerDesc">
                记录家族脉络，传承 家训，组织家族活动
              </div>
              <div class="itemContainerImgDot">

              </div>
              <div class="itemContainerImg">

              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- </van-swipe-item> -->
    <!-- 第三张 -->
    <!-- <van-swipe-item
        style="background:#000000;"
        class="vantItem"
      > -->
    <div class="itemContainer">

    </div>
    <!-- </van-swipe-item>
    </van-swipe> -->
  </div>
</template>
<script>
export default {
  name: "guidance",
  data() {
    return {};
  },
  methods: {
    goApple(){
      console.log(111)
     window.location.href='https://apps.apple.com/cn/app/id1473078077'
    },
    goDownLoad(){
      window.location.href="https://www.yunzhongci.com/download/";
    }
  },
};
</script>
<style lang="scss" scoped>
.boxAll{
  background-image: url('../../../assets/images/guidebg@2x.png');
  background-size: 100%;
}
.topTitle {
  position: fixed;
  z-index: 9;
  top: 0;
  width: 100%;
  height: 0.92rem;
  opacity: 1;
  background: #1a2230;
  font-size: 0.19rem;
  color: #b9782d;
  line-height: 0.92rem;
  display: flex;
  align-items: center;
}
.topLogo {
  width: 0.47rem;
  height: 0.51rem;
  margin-left: 0.29rem;
  margin-right: 0.1rem;
}

.swiperItemTitle {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}
.swiperItemTitlePc {
  padding-top: 1.73rem;
  font-size: 0.5rem;
  font-family: Source Han Sans CN Bold, Source Han Sans CN Bold-Bold;
  font-weight: 700;
  text-align: center;
  color: #f6c943;
  background-image: -webkit-linear-gradient(bottom, #b8832d, #f6c943);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  .arrowImg{
    width: 0.3rem;
    height: 0.29rem;
    margin: 0 0.16rem;
  }
}
.swiperItemTitleMob {
  padding-top: 0.3rem;
  font-size: 0.5rem;
  font-family: Source Han Sans CN Bold, Source Han Sans CN Bold-Bold;
  font-weight: 700;
  text-align: center;
  color: #f6c943;
  background-image: -webkit-linear-gradient(bottom, #b8832d, #f6c943);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
   .arrowImg{
    width: 0.2rem;
    height: 0.29rem;
    margin: 0 0.16rem;
  }
}
.swiperItemTitlePcImg{
  width: 80%;
  margin: 0 auto;
  margin-top: 0.62rem;
}
.swiperItemTitlePcImg img{
  width: 100%;
}
.swiperItemTitlePcTextAll{
  width: 85%;
  margin: 0 auto;
  margin-top:0.19rem;
  display: flex;
  justify-content: space-between;
  .swiperItemTitlePcTextItem{
    width: 1.68rem;
    color: #ffffff;
    text-align: center;
  }
}
.swiperItemTitleMobImg{
  width: 80%;
  margin: 0 auto;
  margin-top: 0.62rem;
}
.swiperItemTitlePcMob img{
  width: 100%;
}
.odelRecommend {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  margin-top: 0.62rem;
  .modelRecommendItem {
    display: flex;
    justify-content: space-between;
    .modelRecommendItemLeft {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1.68rem;
      .modelRecommendItemTop {
        width: 0.81rem;
        height: 0.81rem;
        border-radius: 50%;
        margin: 0 auto;
         img{
           width: 100%;
           height: 100%;
           border-radius: 50%;
         }
      }
      .modelRecommendItemUnd {
        color: #ffffff;
        font-size: 0.22rem;
      }
      .modelRecommendItemUndTitle {
        font-size: 0.22rem;
        color: #ffffff;
        margin-top: 0.19rem;
        margin-bottom: 0.16rem;
      }
    }
  }
  .modelRecommendArrows {
    color: #f6c943;
    margin-top: 0.25rem;
  }
}
.downLoadAppBtn {
  width: 3.94rem;
  height: 1.01rem;
  opacity: 1;
  background: #bb862e;
  border-radius: 0.51rem;
  font-size: 0.38rem;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.01rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 0.7rem;
}
.modelRecommendTextAll {
  width: 100%;
  .modelRecommendText {
    width: 5.46rem;
    opacity: 1;
    font-size: 0.3rem;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: left;
    color: #e2dddd;
    line-height: 0.44rem;
    letter-spacing: 0.04rem;
    margin: 0 auto;
    padding-top: 1.69rem;
  }
}
.containerTwoAll {
  .itemContainerTWOTitle {
    text-align: center;
    padding-top: 1.06rem;
    color: #f6c943;
    background-image: -webkit-linear-gradient(bottom, #b8832d, #f6c943);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 0.42rem;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 500;
  }
  .itemContainerTwo {
    padding-bottom: 0.3rem;
    padding-top: 0.4rem;
    display: flex;
    width: 100%;
    justify-content: space-around;
    .itemContainerTwoDiv{
      width: 80%;
      margin: 0 auto;
    }
    .itemContainerTwoLeft {
      margin: 0 auto;
      width: 2.65rem;
      .itemContainerTwoLeftItem {
        margin-bottom: 0.81rem;
        .itemContainerTwoItemTitle {
          font-size: 0.36rem;
          font-family: Source Han Sans CN Medium,
            Source Han Sans CN Medium-Medium;
          font-weight: 500;
          color: #f6c943;
          background-image: -webkit-linear-gradient(bottom, #b8832d, #f6c943);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .itemContainerDesc {
          width: 2.14rem;
          font-size: 0.22rem;
          font-family: Source Han Sans CN Regular,
            Source Han Sans CN Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #a7a7a7;
          margin-top: 0.23rem;
          margin-bottom: 0.20rem;
        }
        .itemContainerImgDot {
          margin-bottom: 0.19rem;
        }
        .itemContainerImg {
          width: 2.65rem;
          height: 3.58rem;
          opacity: 1;
          background: #1fbc84;
          border-radius: 0.1rem;
        }
      }
    }
    .line {
      width: 0.01rem;
      height: 100%;
      opacity: 1;
      background: #ceb17b;
    }
    .itemContainerTwoRight {
      margin: 0 auto;
      margin-top: 1.77rem;
      width: 2.65rem;
      .itemContainerTwoLeftItem {
        margin: 0 auto;
        margin-bottom: 1.03rem;
        .itemContainerTwoItemTitle {
          font-size: 0.36rem;
          font-family: Source Han Sans CN Medium,
            Source Han Sans CN Medium-Medium;
          font-weight: 500;
          color: #f6c943;
          background-image: -webkit-linear-gradient(bottom, #b8832d, #f6c943);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .itemContainerDesc {
          width: 2.14rem;
          font-size: 0.22rem;
          font-family: Source Han Sans CN Regular,
            Source Han Sans CN Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #a7a7a7;
          margin-top: 0.23rem;
          margin-bottom: 0.20rem;
        }
        .itemContainerImgDot {
          margin-bottom: 0.19rem;
        }
        .itemContainerImg {
          width: 2.65rem;
          height: 3.58rem;
          opacity: 1;
          background: #1fbc84;
          border-radius: 0.1rem;
        }
      }
    }
  }
}
.downLoads{
  display: flex;
  justify-content: center;
   .show-click {
    display: flex;
    z-index: 1;
     a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      font-size: 0.2rem;
      width: 2.4rem;
      height: 0.80rem;
      border: 0.01rem solid #fff;
      margin: 0.40rem 0.1rem;
      border-radius: 0.1rem;
      background: rgba(244,200,67,.8);
    }
     .app-icon {
    width: 0.46rem;
    height: 0.53rem;
    margin-right: 0.17rem;
  }
  }
 
}


</style>